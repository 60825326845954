@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
body {
  margin: 0;
  font-family: 'Rubik';
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Minter {
  max-width: 80vh;
  max-height: 80vh;
  padding: 20px 0px;
  margin: 0 auto;
  text-align: left;
}


button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #000000;
  color: #dd2596;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

#title {
  padding-top: 20px;
  font-size: 45px;
  font-weight: 500;
}

#status {
  color: red;
  font-size: 18px;
  padding-top: 5px;
  text-align: center;

}

#contract {
  color: red;
  font-size: 12px;
  text-align: left;
  font-weight: bold;
  margin-top: 0;

}

#countDown {
  color: goldenrod;
  font-size: 20px;
  text-align: left;
  font-weight: bold;

}


@media only screen and (min-width: 1025px) {

  #status {
    color: red;
    padding-top: 15px;
    text-align: left;
    font-size: 20px;

  }

  #title {
    padding-top: 32px;
    font-size: 50px;
    font-weight: 500;
  }

  #contract {
    color: red;
    font-size: 20px;
    text-align: left;
    font-weight: bold;

  }

  #countDown {
    color: goldenrod;
    font-size: 30px;
    text-align: left;
    font-weight: bold;

  }

}


#walletButton {
  text-align: center;
  float: right;
  margin-bottom: 50px;
  margin-right: 2%;
  line-height: 16px;
}

#walletButton:hover {
  background-color: lightgray
}


#refreshButton {
  margin-left: 25px;
}

#refreshButton:hover {
  background-color: lightgray;
}




#mintButton {
  margin-top: 20px;
  background-color: #dd2596;
  color: white;
  padding: 10px 29px;
}

#mintButton:hover {
  background-color: #641747;

}

#increNumButton {

  background-color: #dd2596;
  color: white;
  width: 60px;
  margin-left: 10px;

}


#increNumButton:hover {
  background-color: #641747;

}

#decreNumButton {

  background-color: #dd2596;
  color: white;
  width: 60px;

}

#decreNumButton:hover {
  background-color: #641747;

}


#pricePerMint {
  color: rgb(224, 2, 124);
  font-size: 18px;
  text-align: left;
  font-weight: bold;

}


#maxMint {
  color: black;
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10%;

}

#mintNumber {

  font-size: 22px;
  text-align: left;
  font-weight: bold;

}


#orderDetail {
  color: rgb(0, 0, 0);
  font-size: 18px;



}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

